.change-page {
  margin-left: auto;
  margin-right: 45px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.project_page {
  justify-content: flex-end;
  margin-right: 0;
}

.hashes-page {
  position: absolute;
  right: 5%;
  margin-right: 0;
}
.change-page__btn {
  background: #3b4255;
  border-radius: 6px;
  padding: 3px 15px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.change-page__arrow {
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 7px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.change-page__arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.change-page__dropdown {
  position: absolute;
  background: #ffffff;
  color: #000000;
  border-radius: 6px;
  z-index: 1;
  top: 30px;
  width: 50%;
  right: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  overflow: hidden;
}
.project__dropdown {
  width: 63px;
}
.change-page__dropdown--item {
  padding: 10px 0;
}
.change-page__dropdown--item:hover {
  background: #dddddd;
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
  .change-page {
    margin-right: 0;
  }
  .hashes-page {
  }
}
