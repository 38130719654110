.intersection-range {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 75px 0 0;
  font-family: "Roboto", sans-serif;
}

.intersection-range__title {
  font-weight: 500;
  font-size: 18px;
  flex: 0 0 10%;
}

.intersection-range__inputs {
  flex: 0 0 30%;
}

.intersection-range__btn {
  flex: 0 0 30%;
}

.intersection-range__btn .submit-btn {
  padding: 10px;
  margin-top: 0;
}

.leave-btn {
  border: 1px solid #fa5252;
  color: #fa5252;
  background: none;
  outline: none;
  border-radius: 8px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 0 0 60%;
}

.intersection-range__percentages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.intersection-range__bar-wrapper {
}

.intersection-range__percentage {
  font-weight: 400;
  font-size: 12px;
  flex: 0 0 31%;
}

.intersection-range__max {
  position: absolute;
  right: 0;
}

.intersection-range__bars {
  background: #3b4255;
  height: 12px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

.intersection-range__bar {
  height: 4px;
  flex: 0 0 31%;
  border-radius: 4px;
}

.intersection-range__values {
  color: #808080;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
}

.intersection-range__input {
  margin: 40px 0;
}

.intersection-range__permissions {
  border-top: none;
  border-bottom: 1px solid #808080;
  margin-top: 0;
  padding-bottom: 15px;
}

.intersection-range__input--label {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 16px;
  display: block;
}

.intersection-range__input--medium {
  position: relative;
}

.intersection-range__input--medium input {
  display: block;
  background: none;
  width: 100%;
  border: none;
  padding-bottom: 10px;
  color: #ffffff;
  outline: none;
  border-bottom: 1px solid #808080;
}

.intersection-range__input--medium:after {
  content: "";
  position: absolute;
  display: block;
  background: #ffc531;
  right: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 5px;
}

.intersection-range__input--main {
  position: relative;
}

.intersection-range__input--main input {
  display: block;
  background: none;
  width: 100%;
  border: none;
  padding-bottom: 10px;
  color: #ffffff;
  outline: none;
  border-bottom: 1px solid #808080;
}

.intersection-range__input--main:after {
  content: "";
  position: absolute;
  display: block;
  background: #ff1a1a;
  right: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 5px;
}

.intersection-range__text {
  font-weight: 400;
  font-size: 14px;
  color: #808080;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .intersection-range__btn {
    flex: 0 0 20%;
  }

  .intersection-range__inputs {
    flex: 0 0 40%;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .intersection-range {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .intersection-range__btn {
    flex: 0 0 50%;
  }
  .leave-btn__wrapper {
    flex: 0 0 20%;
  }

  .intersection-range__inputs {
    order: 1;
    flex: 0 0 50%;
    margin: 25px 0 0;
  }
}

@media (max-width: 575px) {
  .intersection-range__inputs {
    flex: 0 0 100%;
  }
}
