.user-dropdown {
  position: absolute;
  width: 320px;
  top: 66px;
  right: 3px;
  background: #3c3c3c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #a9a9a8;
  /* solid tab */

  box-shadow:
    4px 2px 6px rgba(0, 0, 0, 0.1),
    10px 7px 40px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 14px 17px;
  z-index: 15;
}
.user-dropdown__rectangle {
  position: absolute;
  top: -9px;
  right: 10px;
}

@media (max-width: 575px) {
  .user-dropdown__rectangle {
    right: 19%;
  }
}
