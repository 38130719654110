.subscribe__form {
  border: 2px solid #b6b6b6;
  border-radius: 10px;
  padding: 30px 25px;
  width: 70%;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.subscribe__title {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 54px;
}

.subscribe__label {
  font-weight: 500;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 9px;
}

/*
Radio
*/

.subscribe__radio-box {
  display: flex;
  align-items: center;
  position: relative;
  font-family: "Roboto", sans-serif;
  flex: 0 0 32%;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.subscribe__radio-box .subscribe__radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.subscribe__radio-checkmark {
  position: relative;
  order: -1;
  margin-right: 8px;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.subscribe__radio-box:hover .subscribe__radio ~ .subscribe__radio-checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.subscribe__radio-box .subscribe__radio:checked ~ .subscribe__radio-checkmark {
  background-color: #5abe60;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.subscribe__radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.subscribe__radio-box .subscribe__radio:checked ~ .subscribe__radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.subscribe__radio-box .subscribe__radio-checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.subscribe__radio-boxes {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscribe__item {
  margin-bottom: 41px;
}
.subscribe__item:last-child {
  margin-bottom: 0;
}

.subscribe__btn {
  background: linear-gradient(270deg, #5ebc84 0%, #6bbf44 100%);
  border-radius: 3.89061px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 25%;
}

.subscribe__text {
  width: 70%;
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  margin: 40px 0 37px;
}
.subscribe__text span {
  color: #5abe60;
  text-decoration: underline;
}
.subscribe__follow {
  color: #ffffff;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 36px;
}
