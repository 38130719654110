.notifications {
  margin-right: 10px;
  font-family: "Roboto", sans-serif;
}

.notifications__icon {
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  padding: 6px;
  transition: all 0.1s linear;
}

.notifications__icon:hover {
  background: #3b4255;
}

.notifications__icon--active {
  background: #5abe60;
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  padding: 6px;
  color: #000000;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 10px;
  transition: all 0.1s linear;
  border: 1px solid transparent;
}

.notifications__icon--active:hover {
  border-color: #ebedff;
}

.notifications__icon--active svg {
  margin-right: 8px;
}

.notifications__title {
  color: #ffffff !important;
}

.notifications__close {
  cursor: pointer;
}

.notifications__close:hover svg path {
  fill: #ffffff;
}

.notifications__read {
  color: #a9a9a8;
  margin: 29px 0 24px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #a9a9a8;
  padding: 0 10px 15px;
}

.notifications__clear,
.notifications__as-read {
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
  color: #a9a9a8;
}

.notifications__clear button {
  background: none;
  color: #808080;
  border: none;
  cursor: pointer;
}

.notifications__clear button:first-child {
  margin-right: 8px;
}

.notifications__clear button:hover {
  color: #5abe60;
}

.notifications__clear:hover,
.notifications__as-read:hover {
  color: #ffffff;
}

/* notification */
.notification {
  font-weight: 500;
  font-size: 12px;
  margin: 12px 0;
  background: #3b4255;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: all 0.1s linear;
}

.notification:hover {
  border-color: #55aaf9;
}

.notification__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #5abe60;
  padding: 12px;
}

.notification__top--status {
  color: #5abe60;
  cursor: pointer;
}

.notification__top--status--read {
  color: #a9a9a8 !important;
}

.notification__top--date {
  font-weight: 400;
  color: #a9a9a8;
}

.notification__top--date:hover svg path {
  fill: #ffffff;
}

.notification__top--date svg {
  margin-left: 10px;
  cursor: pointer;
  color: #a9a9a8;
}

.notification__content {
  margin-top: 15px;
  font-weight: 500;
  font-size: 14px;
  white-space: pre-wrap;
  cursor: pointer;
  word-break: break-all;
  word-wrap: break-word;
}

.notification__content--error {
  color: #fa5252;
}

.notification__content--try-again {
  color: #a9a9a8;
  margin-top: 8px;
}

.notification__teams {
  margin-top: 12px;
  font-size: 12px;
  color: #a9a9a8;
  font-weight: 400;
}

.notification__teams--btn {
  background: #535353;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #55aaf9;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;
}

.notification__content {
  padding: 0 12px 12px;
}

@media (max-width: 767px) {
  .notification__content {
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  .notification__content {
    font-size: 10px;
  }
}
