.onboard {
  background: #ebedff;
  width: 640px;
  margin: 50px auto 50px;
  box-shadow:
    4px 2px 6px rgba(0, 0, 0, 0.1),
    10px 7px 40px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
  padding: 44px 25px;
  font-family: "Roboto", sans-serif;
}
.onboard__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 44px 0 0;
}
.onboard__title {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}
.onboard__slider--wrap {
  max-width: 640px;
  margin-top: 25px;
  position: relative;
  overflow: hidden;
}
.onboard__slider--item {
}
.onboard__slider--item img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.onboard__skip,
.onboard__next {
  border-radius: 10px;
  background: none;
  flex: 0 0 30%;
  padding: 12px 0;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s linear;
  margin: 0 5px;
}
.onboard__skip {
  background: #ffffff;
  color: #000000;
}
.onboard__skip:hover {
  border-color: #3b4255;
}
.onboard__next {
  background: #3b4255;
  color: #ffffff;
}
.onboard__next:hover {
  background: #000000;
}
.onboard__slider--item img {
  min-width: 100%;
  height: auto;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.dots {
  margin-top: -4.5px;
  background: #171717;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot {
  border: none;
  background: #ffffff;
  height: 5px;
  width: 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50%;
}

.dot-active {
  height: 7px;
  width: 7px;
  background: #23d86b;
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .onboard {
    width: 480px;
  }
  .onboard__slider--wrap {
    max-width: 480px;
  }
}

@media (max-width: 575px) {
  .onboard {
    width: 340px;
  }
  .onboard__slider--wrap {
    max-width: 340px;
  }
}
