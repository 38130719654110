.ticket-message-form-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 250px;
  padding: 22px 0 30px;
  border-top: 1px solid #3b4255;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #171717;
  z-index: 5;
}

.ticket-message__form-wrapper--hidden {
  left: 70px;
}
.ticket-message-form {
  flex: 0 0 35%;
}
.ticket-message__input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ticket-message-form__input {
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  width: 100%;
  margin-right: 12px;
  border-bottom: 1px solid #808080;
  resize: none;
  display: block;
  height: 100%;
}
.ticket-message-form__input::placeholder {
  font-family: "Roboto", sans-serif;
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}
.ticket-message-form__btn {
  background: none;
  border: none;
  cursor: pointer;
}
.ticket-message-form__btn:hover svg path {
  fill: #23d86b;
}
.ticket-message-form__file-label {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #808080;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
.ticket-message-form__file-label:hover .trash {
  fill: #f74149;
}

.ticket-message-form__file-label:hover .add {
  fill: #23d86b;
}
.ticket-message-form__file-label svg {
  margin-right: 10px;
}
.ticket-message-form__file-label input {
  display: none;
  visibility: hidden;
}

@media (max-width: 1200px) {
  .ticket-message-form {
    flex: 0 0 45%;
  }
}

@media (max-width: 991px) {
  .ticket-message__form-wrapper {
    left: 70px;
  }
  .ticket-message-form {
    flex: 0 0 55%;
  }
}

@media (max-width: 767px) {
  .ticket-message-form {
    flex: 0 0 70%;
  }
}

@media (max-width: 575px) {
  .ticket-message-form {
    flex: 0 0 100%;
  }
  .ticket-message__form-wrapper--hidden,
  .ticket-message-form-wrapper {
    left: 0;
    padding: 22px 15px 30px;
  }
}
