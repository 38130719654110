.resultsContainer {
  background: linear-gradient(135deg, #1e1e1e, #2a2a2a);
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out !important;
  width: 100%;
  position: absolute;
  overflow-x: clip;
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
  &::-webkit-scrollbar-thumb {
    background: #4caf50;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }
}

.resultsContainerChild {
  position: relative;
  display: flex;
  justify-content: center;
}

.summary {
  font-weight: bold;
  margin-bottom: 12px;
  color: #e0e0e0;
  font-size: 18px;
}

.project {
  margin-top: 16px;
  padding: 14px;
  border-left: 4px solid #4caf50;
  background: rgba(76, 175, 80, 0.1);
  color: #a5d6a7;
  border-radius: 8px;

  & * {
    font-size: 14px;
  }
}

.file {
  margin-top: 12px;
  padding: 10px;
  border-left: 3px solid #757575;
  background: rgba(97, 97, 97, 0.1);
  border-radius: 6px;

  h4 {
    color: #b0bec5;
  }
}

.snippets {
  list-style-type: none;
  padding-left: 12px;
}

.snippet {
  background: #2e2e2e;
  color: #e0e0e0;
  padding: 8px;
  border-radius: 6px;
  margin-top: 6px;
  font-family: monospace;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.noResults {
  color: #ff5252;
  font-weight: bold;
  text-align: center;
}
