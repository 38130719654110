.edit-project__name {
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.edit-project__type {
  pointer-events: none;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.edit-project__team {
  font-size: 14px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid #c6c7c7;
  padding-bottom: 9px;
}

.edit-project__delete {
  background: transparent;
  margin: 15px auto 0;
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  width: 50%;
  border: 1px solid #3b4255;
  border-radius: 6px;
  padding: 10px 16px;
  font-weight: 400;
  color: #171717;
  font-size: 14px;
  transition: all 0.1s linear;
  cursor: pointer;
}

.edit-project__delete:hover {
  background: #171717;
  color: #ffffff;
}

.edit-project__btn {
  background: transparent;
  border-radius: 6px;
  font-weight: 500;
  color: #000000;
  font-size: 14px;
  padding: 6px 8px;
  cursor: pointer;
  display: flex;
  border: 2px solid #000000;
  align-items: center;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
}

.edit-project__btn:hover {
  background: #171717;
  color: #ffffff;
}

.edit-project__btn:hover svg path {
  fill: #ffffff;
}

.edit-project__btn svg {
  margin-right: 7px;
}

.edit-project__wrapper {
  justify-content: flex-start !important;
}

@media (max-width: 767px) {
  .edit-project__delete {
    width: 80%;
  }
}

@media (max-width: 575px) {
  .edit-project__delete {
    width: 90%;
  }

  .edit-project__btn {
    font-size: 12px;
  }
}
