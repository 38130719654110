body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #171717;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
.main {
  flex: 1 0 auto;
  grid-area: main;
  padding-left: 70px;
  transition: all 0.2s ease-in-out;
}
.main-opened {
  padding-left: 250px;
}
.container {
  max-width: 1170px;
  margin: 0 auto;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1200px) {
}

@media (max-width: 991px) {
  .container {
    max-width: calc(100% - 15px);
  }
  .main {
    padding-left: 0;
  }
  .main-opened {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 27px !important;
  }
}
