.team-roles__permissions {
  border-top: 0;
  margin-top: 20px;
  display: block;
}
.team-roles__role {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  padding-bottom: 20px;
  margin: 10px 0 !important;
  position: relative;
}
.team-roles__role:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 92%;
  background: #808080;
  right: 0;
}
.team-roles__label {
  margin-bottom: 0;
  padding-left: 25px !important;
}
.team-roles__info {
  cursor: pointer;
}
.team-roles__text {
  color: #808080;
  font-size: 12px;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 15px;
}
