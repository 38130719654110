.help {
  padding: 0 35px 0 50px;
  position: relative;
}

.help__btn {
  position: absolute;
  cursor: pointer;
  top: 0;
  z-index: 5;
  right: 15%;
}

.open__btn {
  right: 25px;
}

.help__btn--hide {
  transform: rotate(-180deg);
}

.help__info {
  flex: 0 0 70%;
  position: relative;
}

.help__list {
  list-style: none;
  margin: 35px 0;
  padding: 0;
}
.help__sub-list {
  padding-left: 35px;
  font-size: 14px;
  list-style-type: disc;
  margin-bottom: 15px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
.help__sub-list--item {
  margin: 3px 0;
}
.help__item--title {
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  padding-left: 10px;
  margin-top: 35px;
}
.help__item {
  font-weight: 400;
  font-size: 14px;
}

.help__item--text {
  width: 75%;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  padding-left: 10px;
  margin: 10px 0 0;
}

.help__text {
  margin: 32px 0;
  width: 50%;
  font-size: 14px;
  font-weight: 400;
}

.help__text--item {
  font-size: 14px;
  font-weight: 400;
  margin: 32px 0;
  padding-left: 15px;
  position: relative;
}

.help__text--item::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #ffffff;
}

.help__image {
  margin: 32px 0;
}

.help__image img {
  max-width: 500px;
  border-radius: 10px;
  height: auto;
  object-fit: contain;
}

.help__title {
  cursor: pointer;
  border-bottom: 1px solid #808080;
  padding-bottom: 10px;
  position: relative;
}

.onboard__privacy-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.onboard__privacy-item {
  color: #a9a9a8;
  text-decoration: underline #a9a9a8;
  font-weight: 400;
  font-size: 12px;
  transition: all 0.2s linear;
}

.onboard__privacy-item:hover {
  color: #ffffff;
  text-decoration-color: #ffffff;
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
  .help {
    padding: 0 25px;
  }

  .help__title {
    flex: 0 0 28%;
  }

  .help__text {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .help__item--text {
    width: 80%;
  }
}

@media (max-width: 575px) {
  .help__title {
    flex: 0 0 100%;
  }

  .help__image img {
    max-width: 100%;
    border-radius: 10px;
    height: auto;
    object-fit: contain;
  }

  .help__info {
    flex: 0 0 100%;
  }

  .help__item--text {
    width: 100%;
  }

  .help__text {
    width: 100%;
  }
}
