.account {
  padding-left: 50px;
  display: flex;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  margin: 35px 0;
}

.account__title {
  font-size: 18px;
  flex: 0 0 20%;
  font-weight: 500;
}

.account__info {
  font-weight: 400;
  position: relative;
  flex: 0 0 30%;
}

.account__info--form {
  display: flex;
  align-items: flex-start;
  flex: 0 0 50%;
  justify-content: space-between;
}

.account__info--form-item {
  flex: 0 0 60%;
}

.submit-btn {
  display: block;
  margin-top: 25px;
  background: none;
  border-radius: 8px;
  border: 1px solid #808080;
  color: #ffffff;
  padding: 7px 10px;
  cursor: pointer;
}
.submit-btn:hover {
  border-color: #ffffff;
}

.account__name {
  margin-bottom: 30px;
}

.account__text {
  font-size: 14px;
  background: none;
  border: none;
  outline: none;
  flex: 0 0 70%;
  color: #ffffff;
  word-break: break-all;
  white-space: pre-wrap;
}
.account__text:focus,
.account__text:active {
  background: none;
}
.account__text::placeholder {
  color: #ffffff;
  font-size: 14px;
}
.account__text--blue {
  color: #55aaf9;
}

.account__subtitle {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 13px;
  font-weight: 400;
  position: relative;
  flex: 0 0 30%;
}
.account__subtitle--hidden {
  display: none;
  visibility: hidden;
}

.account__subtitle::after {
  content: "";
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #808080;
}

.account__options {
  cursor: pointer;
}

/* telegram*/
.telegram__channel {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;
}

.telegram__text {
  color: #808080;
  flex: 0 0 60%;
  font-size: 14px;
}

.telegram__link {
  color: #55aaf9;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border: 1px solid #55aaf9;
  cursor: pointer;
}

.telegram__link:hover {
  background: #55aaf9;
  color: #000000;
}

.telegram__link:hover svg path {
  fill: #000000;
}

.telegram__link svg {
  margin-left: 5px;
}

@media (max-width: 1200px) {
  .telegram__link {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .account {
    padding: 0 25px;
  }
}

@media (max-width: 767px) {
  .account {
    flex-wrap: wrap;
  }
  .account__info {
    flex-wrap: wrap;
    flex: 0 0 100%;
  }
  .account__info--form-item {
    flex: 0 0 100%;
  }
  .account__title {
    flex: 0 0 100%;
    margin-bottom: 25px;
  }
}

@media (max-width: 575px) {
}
