.notification__label {
  display: block;
  position: relative;
  padding-left: 25px;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.notification__label--disabled {
  pointer-events: none;
}

.notification__checkbox {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.notification__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid #55aaf9;
}

.notification__checkbox:checked ~ .notification__checkmark {
  background-color: #55aaf9;
}

.notification__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.notification__checkbox:checked ~ .notification__checkmark:after {
  display: block;
}

.notification__label:hover::before {
  background: #55aaf9;
  border-radius: 50%;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 5px;
  content: "";
  height: 4px;
  width: 4px;
}

/* Style the checkmark/indicator */
.notification__label .notification__checkmark:after {
  left: 3px;
  top: 1px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
