.ticket {
  width: 30%;
  padding-left: 50px;
  color: #ffffff;
  margin-top: 75px;
}

.ticket__title {
  font-size: 19px;
  font-weight: 400;
}

.ticket__text {
  font-weight: 400;
  font-size: 15px;
}

.account__sub-text {
  color: #808080;
  margin-top: 15px;
  font-size: 14px;
}

.ticket-modal {
  width: 500px;
  top: 5%;
  padding: 25px;
}

.ticket-modal__tags {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  padding-bottom: 5px;
}

.ticket__textarea {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 6px;
  background: #ffffff;
  padding: 10px;
  resize: none;
  min-height: 160px;
}

.ticket-modal__tags--active {
  position: relative;
  background: #79d171 !important;
  color: #000000 !important;
}

.create-ticket__title {
  font-weight: 400;
  text-align: left;
  color: #000000;
  margin: 25px 0 5px;
  font-size: 14px;
}
.ticket-modal__tag {
  flex: 0 0 30%;
  font-weight: 500;
  cursor: pointer;
  font-size: 13px;
  background: #3b4255;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 3px;
  border-radius: 6px;
  padding: 3px 0;
  color: #ffffff;
}
.ticket-modal__tag:hover {
  background: #171717;
}
.ticket-form__file input {
  display: none;
}
.ticket-form__file {
  margin-top: 10px;
  cursor: pointer;
  padding: 10px 12px;
  font-family: "Roboto", sans-serif;
  color: #a9a9a8;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .ticket {
    width: 45%;
  }
  .ticket-modal {
    top: 5%;
  }
}

@media (max-width: 991px) {
  .ticket {
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .ticket-modal {
    max-width: 95%;
    padding: 15px 16px;
  }
  .ticket-modal__tag {
    flex: 0 0 30%;
    margin-bottom: 10px;
  }
  .ticket {
    width: 75%;
    padding: 0 15px;
  }
}

@media (max-width: 575px) {
  .ticket {
    width: 100%;
  }
  .ticket-modal {
    top: 1%;
  }
}
