.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 75px 0 35px;
  position: relative;
}

.flex {
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.pagination__dots {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 14px;
}
.pagination__dot {
  background: #000000;
  display: block;
  height: 3.3px;
  margin: 5px 2px 0;
  width: 3.3px;
  border-radius: 50%;
}
.pagination__dot--white {
  background: #ffffff;
}
.pagination-button {
  cursor: pointer;
  outline: none;
  border: none;
  background: #ffffff;
  border-radius: 6px;
  padding: 6px 14px;
  color: #000000;
  margin-left: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-button:hover {
  border-color: #ffffff;
}

.pagination-button.more {
  border: none;
}

.pagination-button.active {
  background: #55aaf9;
  /* object white */

  /* bg blur */
  backdrop-filter: blur(4px);
  color: #ffffff;
}

.pagination-button__icon--next,
.pagination-button__icon--prev {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.pagination-button__icon--next {
  right: 20%;
}

.pagination-button__icon--prev {
  left: 20%;
}
.pagination-button__icon--first,
.pagination-button__icon--last {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.pagination-button__icon--first {
  left: 15%;
}
.pagination-button__icon--last {
  right: 15%;
}

@media (max-width: 767px) {
  .pagination-button__icon--first {
    left: 7%;
  }
  .pagination-button__icon--last {
    right: 7%;
  }
  .pagination-button__icon--next {
    right: 12%;
  }

  .pagination-button__icon--prev {
    left: 12%;
  }
}

@media (max-width: 575px) {
  .pagination {
    margin-bottom: 50px;
  }
  .pagination-button__icon--first,
  .pagination-button__icon--last,
  .pagination-button__icon--next,
  .pagination-button__icon--prev {
    display: none;
    visibility: hidden;
  }
}
