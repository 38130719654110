.analysis {
  padding: 0 60px;
  margin: 85px 0;
  font-family: "Roboto", sans-serif;
  word-wrap: break-word;
}

.analysis-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.analysis-wrapper__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  z-index: 1;
}

.analysis-wrapper__tabs {
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  width: 100%;
}

.analysis-wrapper__title {
  margin-right: 45px;
  font-weight: 700;
  font-size: 22px;
}

.analysis-wrapper__btn {
  margin: 0 5px;
  background: #3b4255;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  align-items: center;
  display: flex;
  color: #ffffff;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
.analysis-wrapper__btn:hover {
  box-shadow: 0 0 0 1px #79d171;
}

.analysis-wrapper__btn--active {
  background: #79d171;
  color: #000000;
}

.analysis-wrapper__btn span {
  margin-right: 10px;
}

.analysis-wrapper__item {
  position: relative;
}

.analysis-wrapper__search {
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 10px 5px;
}
.filter__input input:focus .analysis-wrapper__search--icon path {
  fill: #ffffff;
}
.analysis-wrapper__search:after {
  display: none;
}
.analysis-wrapper__search::placeholder {
  text-align: center;
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding-left: 25px;
  font-weight: 400;
}

.analysis-wrapper__search--icon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.hidden {
  display: none;
  visibility: hidden;
}

.analysis-data {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media (max-width: 1200px) {
  .analysis-wrapper {
    flex-wrap: wrap;
  }
  .analysis-wrapper__tabs {
    flex-wrap: wrap;
    flex: 0 0 100%;
    justify-content: space-between;
  }
  .analysis-wrapper__item {
    flex: 0 0 20%;
    margin-left: auto;
  }
  .analysis-wrapper__item input {
    margin-left: auto;
    display: block;
  }
}

@media (max-width: 991px) {
  .analysis {
    padding: 0 25px;
  }
  .analysis-wrapper__tabs {
    justify-content: center;
  }
  .analysis-wrapper__item {
    flex: 0 0 30%;
  }
  .analysis-wrapper__title {
    flex: 0 0 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 15px;
  }
  .analysis-wrapper__btn {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .analysis-wrapper__item,
  .filter__input {
    flex: 0 0 50%;
  }
}

@media (max-width: 575px) {
  .analysis {
    padding: 0 15px;
  }
  .analysis-wrapper__item {
    margin-top: 15px;
  }
  .analysis-wrapper__btn {
    font-size: 13px;
  }
}
