.main {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:last-child {
    filter: drop-shadow(0px 0px 10px black);
  }
}
.wrapper {
  max-width: 300px;
  width: 100%;
  height: 40px;
  color: gray;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.icon {
  height: 20px;
}

.input {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}
