.success-wrapper {
  position: relative;
  background: #ebedff;
  box-shadow:
    4px 2px 6px rgba(0, 0, 0, 0.1),
    10px 7px 40px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 650px;
  margin: -150px auto 0;
  min-height: 253px;
  padding: 56px 0 50px;
  font-family: "Roboto", sans-serif;
}

.success-wrapper__exit {
  position: absolute;
  right: 25px;
  top: 25px;
}

.success-wrapper__mail svg {
  margin: 0 auto;
  display: block;
}

.success-wrapper__title {
  text-align: center;
  margin: 26px 0 20px;
  font-weight: 500;
  font-size: 22px;
}

.success-wrapper__text {
  text-align: center;
  width: 50%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

@media (max-width: 767px) {
  .success-wrapper {
    width: 95%;
  }

  .success-wrapper__text {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .success-wrapper__title {
    font-size: 18px;
  }

  .success-wrapper__text {
    font-size: 13px;
  }
}
