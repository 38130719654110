.password__label {
  display: block;
  text-align: left;
  margin: 25px 0 10px;
}
.password__input {
  position: relative;
  width: 100%;
}
.password__show {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.password__error {
  color: #fa5252;
  margin-left: auto;
  text-align: center;
  margin-top: 5px;
  flex: 0 0 25%;
  font-size: 12px;
  font-weight: 400;
  justify-self: flex-end;
}
.password__input input,
.password__input select {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 6px;
  background: #ffffff;
  padding: 10px 25px 10px 10px;
}
.password__input select {
  cursor: pointer;
}
