.members__search {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.members__wrapper {
  margin: 65px auto 35px;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.members__wrapper--text {
  margin-left: 25px;
}

.member-add-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0;
  background: #ebedff;
  padding: 16px 22px;
  border-radius: 10px;
  color: #000000;
}

.member-add-form__info {
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.dark {
  background: #3b4255;
  color: #ffffff;
}

.isUserDark {
  padding: 15px 22px;
}

.member-add-form__title {
  font-size: 20px;
  flex: 0 0 35%;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.member-add-form__me {
  font-weight: 500;
  font-size: 12px;
  color: #3b4255;
  background: #79d171;
  border-radius: 4px;
  padding: 4px 6px;
  margin-left: 10px;
}

.member-add-form__input {
  display: block;
  background: none;
  border: none;
  outline: none;
  padding: 10px 5px;
}

.member-add-form__input:hover,
.member-add-form__input:focus {
  background: #ffffff;
  border-radius: 6px;
}

.member-add-form__input::placeholder {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.member-add-form__btn {
  background: #ffffff;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px 10px;
  cursor: pointer;
  border: none;
}

.wrapper-member {
  display: flex;
  justify-content: center;
  flex: 0 0 12%;
}

.custom-select__container--member {
  width: 100%;
  position: relative;
}

.wrapper-member .custom-select__btn {
  border: 0;
  border-radius: 4px;
  background: transparent;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  padding: 20px 8px;
  position: relative;
  text-align: left;
}

.wrapper-member .custom-select__btn:hover {
  cursor: pointer;
}

.wrapper-member .custom-select__btn::after {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  position: absolute;
  right: 10px;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: all 0.3s linear;
}

.wrapper-member .custom-select__btn.expanded::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.wrapper-member ul.options {
  display: none;
  list-style: none;
  padding: 0;
}

.wrapper-member ul.show {
  display: block;
  position: absolute;
  top: 70px;
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  z-index: 5;
}

.wrapper-member ul.options li {
  padding: 10px;
}

.wrapper-member ul.options li:active,
.wrapper-member ul.options li:focus,
.wrapper-member ul.options li:hover,
.wrapper-member ul.options li[aria-selected="true"] {
  background: #d8d9da;
  cursor: pointer;
}

.member-item__email {
  color: #5abe60;
}

.member-item__text {
  flex: 0 0 12%;
}

.member-add-form__permissions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 0 0 100%;
}

.member-add-form__role {
  color: #55aaf9;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 8px;
}

.member-add-form__permission {
  background: #808080;
  border-radius: 4px;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
}

.member-add-form__permission-Wrapper {
  display: flex;
  align-items: center;
}

.create-team__select {
  display: flex;
  justify-content: flex-end;
  width: 25%;
  position: absolute;
  color: #000000;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.custom-select__container--member {
  width: 100%;
  position: relative;
}

.create-team__select .custom-select__btn {
  border: 0;
  border-radius: 4px;
  background: transparent;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  color: #000000;
  padding: 20px 8px;
  position: relative;
  text-align: left;
}

.create-team__select .custom-select__btn:hover {
  cursor: pointer;
}

.create-team__select .custom-select__btn::after {
  content: "";
  border: solid #000000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 2px;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: all 0.3s linear;
}

.create-team__select .custom-select__btn.expanded::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.create-team__select ul.options {
  display: none;
  list-style: none;
  padding: 0;
}

.create-team__select ul.show {
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
  background: #ebedff;
  width: 144px;
  color: #000000;
  border-radius: 6px;
  overflow: hidden;
  z-index: 5;
}

.wrapper-member-item__btn {
  border-radius: 6px;
  color: #ffffff;
  background: #c6c7c7;
  border: none;
  padding: 5px 10px;
}

.create-team__select ul.options li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 5px;
  cursor: pointer;
}

.create-team__select ul.options li:active,
.create-team__select ul.options li:focus,
.create-team__select ul.options li:hover,
.create-team__select ul.options li[aria-selected="true"] {
  cursor: pointer;
  background: #3b4255;
  color: #ffffff;
}

@media (max-width: 1200px) {
  .wrapper-member-item {
    flex: 0 0 15%;
  }

  .wrapper-member-item .custom-select__btn {
    font-size: 14px;
  }

  .wrapper-member {
    flex: 0 0 15%;
  }

  .wrapper-member .custom-select__btn {
    font-size: 14px;
  }

  .member-add-form__title {
    font-size: 17px;
  }

  .member-item__email {
    font-size: 14px;
    flex: 0 0 10%;
  }

  .member-add-form__btn {
    font-size: 13px;
  }

  .member-add-form__input::placeholder {
    font-size: 14px;
  }

  .members__wrapper {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .members__wrapper {
    margin-top: 0;
  }
  .member-add-form {
    padding: 8px 13px;
    flex-wrap: wrap;
  }

  .member-add-form__title {
    font-size: 13px;
  }

  .member-item__email {
    font-size: 12px;
  }

  .wrapper-member-item {
    flex: 0 0 20%;
  }

  .wrapper-member-item .custom-select__btn,
  .wrapper-member .custom-select__btn {
    font-size: 12px;
  }

  .wrapper-member {
    flex: 0 0 20%;
  }
}

@media (max-width: 575px) {
  .wrapper-member-item {
    margin-right: 0;
    flex: 0 0 30%;
  }

  .wrapper-member {
    flex: 0 0 30%;
  }

  .member-add-form__title {
    flex: 0 0 60%;
  }

  .member-item__email {
    flex: 1 0 auto;
  }

  .wrapper-member-item .custom-select__btn,
  .wrapper-member .custom-select__btn {
    padding: 0 3px;
    margin-top: 20px;
  }

  .member-add-form__input {
    flex: 0 0 15%;
    max-width: 100%;
    display: block;
  }

  .wrapper-member-item ul.show {
    left: 0;
  }

  .member-add-form__btn {
    margin-top: 15px;
  }

  .project-database__item--btn {
    padding: 5px 10px;
    margin-top: 15px;
  }
}
