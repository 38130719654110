.ticket-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a9a9a8;
  padding-bottom: 13px;
  border-bottom: 1px solid #3b4255;
}

.ticket-info__breadcrumb {
  display: flex;
  align-items: center;
}

.ticket-info__breadcrumb span {
  margin-left: 10px;
}

.ticket-info__status {
  color: #6bbf44;
}

@media (max-width: 575px) {
  .ticket-info {
    font-size: 9px;
  }
}
