.custom-select__file {
  display: flex;
  width: 100%;
  background: #ffffff;
  color: #000000;
  border: none;
  padding: 25px 8px;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.custom-select__file div {
  color: #000000;
}
.custom-select__list {
  position: absolute;
  list-style: none;
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  top: 70px;
  z-index: 1;
}

.custom-select__import {
  padding: 10px;
  cursor: pointer;
  word-break: break-all;
}

.custom-select__import:hover {
  background: #d8d9da;
}

.custom-select__import label,
.custom-select__import div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-team__github {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrow {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  position: absolute;
  right: 10px;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: all 0.3s linear;
}
.arrow-active {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
