.languages__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.languages__btn span {
  text-transform: uppercase;
  margin-left: 3px;
  font-size: 15px;
}

.languages__dropdown {
  background: #ecedf5;
  border-radius: 10px;
  position: fixed;
  top: 84px;
  right: -200%;
  min-width: 250px;
  min-height: 130px;
  transition: right 0.3s ease;
  color: #000000;
  z-index: 150;
  font-family: "Roboto", sans-serif;
  padding: 10px 0;
}
.languages__dropdown svg {
  cursor: pointer;
}
.languages__dropdown--active {
  right: 0;
}

.languages__list {
  list-style: none;
  padding: 0;
}

.languages__dropdown--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 17px;
  border-bottom: 2px solid #808080;
  padding: 0 15px 15px;
}

.languages__dropdown--header img {
  cursor: pointer;
}

.languages__item {
  padding: 15px;
  cursor: pointer;
}
.languages__item:hover {
  background: #d8d9da;
}
.languages__item a {
  display: block;
  width: 100%;
}
