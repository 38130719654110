.mods__intro {
  background: linear-gradient(270deg, #5ebc84 0%, #6bbf44 100%);
  font-family: "Roboto", sans-serif;
  padding: 75px 60px 35px;
  color: #252425;
}

.mods__intro--title {
  margin-bottom: 15px;
  font-size: 28px;
  color: #000000;
  font-weight: 700;
  word-break: break-all;
}

.mods__intro--btn-wrapper {
  display: flex;
}

.mods__reindex {
  margin: 0 5px 0 0;
  border: 2px solid #000000;
  color: #000000;
}

.mods__reindex--disabled:hover {
  color: rgba(118, 118, 118, 0.3) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
  background: none !important;
}

.mods__reindex--disabled:hover svg path {
  fill: rgba(118, 118, 118, 0.3);
}

.mods__reindex--disabled svg path {
  fill: rgba(118, 118, 118, 0.3);
}

.mods__intro--text {
  font-size: 14px;
  font-weight: 400;
}

/* mods-current */
.mods-current {
  padding: 25px 60px;
  font-family: "Roboto", sans-serif;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.mods-current__list {
  list-style: none;
  padding: 0;
  flex: 0 0 50%;
}

.mods-current__item {
  margin: 25px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.mods-current__item:first-child {
  margin-top: 10px;
}

.mods-current__item--key {
  color: #808080;
  width: 30%;
}

.mods-current__item-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.mods-current__progress {
  position: absolute;
  width: 100%;
  background: #c6c7c7;
  bottom: -5px;
  height: 1px;
}

.mods-current__progress--width {
  position: absolute;
  background: #55aaf9;
  height: 1px;
  left: 0;
}

.mods-current__item--value {
  color: #ffffff;
  font-weight: 500;
  margin-left: 0;
  position: relative;
}

.mods-current__item--active {
  color: #79d171;
}

/* Compare */

.compare {
  font-family: "Roboto", sans-serif;
  background: #ffffff;
  border-radius: 10px;
  box-shadow:
    4px 2px 6px rgba(0, 0, 0, 0.1),
    10px 7px 40px rgba(0, 0, 0, 0.15);
  padding: 25px 40px;
  margin: -150px 75px 0 0;
  width: 35%;
}

.mods-form__select,
.mods-form__search {
  display: block;
  width: 100%;
  background: #ecedf5;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 10px 10px;
}

.mods-form__select::placeholder,
.mods-form__search::placeholder {
  color: #000000;
  font-size: 14px;
}

.mods-form__select {
  margin: 25px 0 5px;
  padding-right: 10px;
}

.mods-form__title {
  font-size: 16px;
}

.mods-form__search {
  position: relative;
}

.mods-form__search-panel {
  position: relative;
}

.mods-form__search--btn {
  position: absolute;
  right: 10px;
  top: 50%;
  background: none;
  border: none;
  outline: none;
  transform: translateY(-50%);
}

.mods-form__reset {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto 40px;
  background: #000000;
  border-radius: 10px;
  color: #ffffff;
  padding: 12px 34px;
  font-weight: 400;
  font-size: 14px;
}

.compare-info {
}

.compare__text {
  color: #808080;
}

.compare__result {
  width: 100%;
  height: 5px;
  border-radius: 10px;
  margin: 10px 0;
  background: #ecedf5;
  position: relative;
}

.compare__result::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 21.42%;
  background: #f64149;
}

.compare__percentage {
  text-align: center;
}

@media (max-width: 1200px) {
  .compare {
    margin: -85px 0 0 0;
    width: 50%;
  }
}

@media (max-width: 991px) {
  .mods-current {
    padding: 15px 25px;
  }

  .mods__intro {
    padding: 75px 25px 35px;
  }

  .compare {
    margin-top: -60px;
  }
}

@media (max-width: 767px) {
  .mods-current {
    flex-wrap: wrap;
  }

  .compare {
    flex: 0 0 75%;
    margin: 15px auto;
  }

  .mods-current__list {
    flex: 0 0 100%;
  }
}

@media (max-width: 575px) {
  .compare {
    flex: 0 0 100%;
    margin: 15px auto;
  }

  .mods-current {
    padding: 15px;
  }

  .mods__intro {
    padding: 50px 25px 30px;
  }

  .mods-current__list {
    flex: 0 0 100%;
  }

  .mods__reindex {
    font-size: 12px;
  }
}
